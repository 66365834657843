import { Component, OnInit } from '@angular/core';
import {NotificationsService} from "../../../../services/Notifications/notifications.service";
import {IotService} from "../../../../services/iot/iot.service";
import {StudentStudyYear} from "../../../../models/iot/student-study-year.model";
import {
  AddElectiveDisciplineStudentCommand, DeleteElectiveDisciplineStudentCommand,
  GetStudentsElectiveDisciplineDto, SignStatementCommand,
  StudentElectiveDiscipline
} from "../../../../models/iot/student-elective-discipline.model";
import {environment} from "../../../../../environments/environment";
import {DateToString} from "../../../../helpers/date-helper";
import {IotEmailSubscriptionService} from "../../../../services/iot/iot-email.subscription.service";

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.scss']
})
export class IotComponent implements OnInit {

  constructor(private notificationService: NotificationsService,
              private iotService: IotService,
              private iotEmailSubscriptionService: IotEmailSubscriptionService
  ) { }

  public iot = environment.iot;
  protected studentStudyYears: StudentStudyYear[] = [];
  protected studentElectiveDisciplines: StudentElectiveDiscipline[] = [];
  protected addElectiveDisciplineStudentCommand: AddElectiveDisciplineStudentCommand | undefined;
  protected studentEnabledEmailNotifications = false;
  public yearAdmission!: number;
  public isCurrentYear = false;
  public hasLoaded = false;
  public hasDeadline = false;
  public cantSign = true;
  public openedDialog = false;
  public openedEmailDialog = false;
  public deadlineDateEnd: Date | undefined;
  today: Date = new Date();

  ngOnInit(): void {
    if (this.iot) {
      this.getStudentStudyYears();
    }
  }

  private getStudentStudyYears() {
    this.iotService.getStudentStudyYears().subscribe(
      (next) => {
        this.studentStudyYears = next;
        this.yearAdmission = this.studentStudyYears[0].studyYear;
        this.getStudentElectiveDisciplines(this.yearAdmission);
      }
    )
  }

  private getStudentElectiveDisciplines(studyYear: number){
    this.iotService.getStudentElectiveDisciplines(studyYear).subscribe(
      (next) => {
        this.studentElectiveDisciplines = next;
        this.deadlineDateEnd = this.studentElectiveDisciplines.find(sed => sed.deadlineDateEnd)?.deadlineDateEnd;
        this.isCurrentYear = this.studentStudyYears.find(x => x.studyYear == this.yearAdmission)?.isCurrentStudyYear === true;
        this.checkIfCanSign();
        this.checkStudentDeadlines();
        this.getStudentSubscriptionEmailStatus();
        this.hasLoaded = true;
      }
    )
  }

  public LoadStudentElectiveDiscipline(studyYear: number) {
    this.hasLoaded = false;
    this.getStudentElectiveDisciplines(studyYear);
  }

  hasAnyEnrolled(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): boolean {
    return electiveDisciplineDto.some(item => item.hasEnrolled);
  }

  hasChosenElectiveDiscipline(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): boolean {
    return electiveDisciplineDto.some(item => item.hasEnrolled && !item.canMakeChoises);
  }

  getElectiveDisciplineName(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): string {
    return electiveDisciplineDto.find(edd => edd.hasEnrolled)?.electiveDisciplineName ?? '';
  }

  getElectiveDisciplineLocation(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): string {
    return electiveDisciplineDto.find(edd => edd.hasEnrolled)?.disciplineLocation ?? '';
  }

  getElectiveDisciplinePersonInfo(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): string {
    const enrolled = electiveDisciplineDto.find(edd => edd.hasEnrolled);
    if (!enrolled) return '';

    const fio = enrolled.fio ?? '';
    const phoneNumber = enrolled.phoneNumber ? `конт. тел. ${enrolled.phoneNumber}` : '';

    return fio && phoneNumber
      ? `${fio}, ${phoneNumber}`
      : fio || phoneNumber;
  }

  checkStudentDeadlines() {
    this.hasDeadline = this.studentElectiveDisciplines
      .some(item => item.electiveDisciplineDto
        .some(x => x.hasDeadline));
  }

  checkIfCanSign(): void {
    this.cantSign = !this.studentElectiveDisciplines.every(dpv =>
      dpv.electiveDisciplineDto.some(discipline => discipline.hasEnrolled) &&
      !dpv.electiveDisciplineDto.some(discipline => discipline.signed)
    );
  }

  closeDialog() {
    this.openedDialog = false;
  }

  closeEmailDialog() {
    this.openedEmailDialog = false;
  }

  openEmailDialog() {
    this.openedEmailDialog = true;
  }

  public checkForSameElectiveDisciplineInStudyYear(dataItem: GetStudentsElectiveDisciplineDto) {
    this.addElectiveDisciplineStudentCommand = new AddElectiveDisciplineStudentCommand(dataItem.electiveDisciplineId, this.yearAdmission);
    if (dataItem.hasSameElectiveDisciplineInStudyYear) {
      this.openedDialog = true;
    } else {
      this.enroll();
    }
  }

  public enroll() {
    this.iotService.addStudentElectiveDiscipline(this.addElectiveDisciplineStudentCommand!).subscribe(
      (next) => {
        this.openedDialog = false;
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

  public unroll(dataItem: GetStudentsElectiveDisciplineDto) {
    const deleteElectiveDisciplineStudentCommand = new DeleteElectiveDisciplineStudentCommand(dataItem.electiveDisciplineStudentId);
    this.iotService.deleteStudentElectiveDiscipline(deleteElectiveDisciplineStudentCommand).subscribe(
      (next) => {
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

  public signStatement() {
    const signStatementCommand = new SignStatementCommand(this.yearAdmission);
    this.iotService.signStatement(signStatementCommand).subscribe(
      (next) => {
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

  private getStudentSubscriptionEmailStatus() {
    this.iotEmailSubscriptionService.getStudentSubscriptionEmailStatus().subscribe(
      (next) => {
        this.studentEnabledEmailNotifications = next;
      }
    )
  }

  protected subscribeOrUnsubscribeStudentToEmail() {
    const method = this.studentEnabledEmailNotifications
      ? this.iotEmailSubscriptionService.unsubscribeStudentFromEmail()
      : this.iotEmailSubscriptionService.subscribeStudentToEmail()
    method.subscribe(
      (next) => {
        this.notificationService.showSuccess('Сохранено');
        this.closeEmailDialog();
        this.getStudentSubscriptionEmailStatus();
      }
    )
  }

  protected readonly DateToString = DateToString;
}
